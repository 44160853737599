import exactSearch from '@helpers/misc/exactSearch'
import {useRef} from '@hooks/useRef'
import {useState} from '@hooks/useState'
import Container from '@packages/justo-parts/lib/components/Container'
import {useTranslation} from 'next-i18next'
import {useEffect} from 'react'
import {MdClose, MdSearch, MdSort} from 'react-icons/md'

import MobileCategoriesMenu from '../../CategoryPage/Content/TopRow/MobileCategoriesMenu'

import MultiBrandSearchBar from './MultiBrandSearchBar'

import styles from './styles.module.css'

export default function Search(props) {
  const {filter, setFilter, showCategories, website, scrollToCategory} = props
  const input = useRef(null)
  const [open, setOpen] = useState(false)
  const {t} = useTranslation('website', {keyPrefix: 'order'})
  const isExact = exactSearch(filter || '')
  const [barBrand, setBarBrand] = useState(true)

  useEffect(() => {
    if (!barBrand) input.current.scrollIntoView({behavior: 'smooth', block: 'center'})
  }, [filter])

  return (
    <div className={styles.container}>
      {open && <MobileCategoriesMenu setOpen={setOpen} />}
      <Container>
        <div className={styles.inner}>
          <div
            className={styles.search}
            onClick={() => {
              if (website.categoryLayoutForMultiBrand) setBarBrand(!barBrand)
              if (!barBrand) input.current.focus()
            }}
          >
            <MdSearch size={20} />
          </div>
          <>
            {website?.isMultiBrand && website?.categoryLayoutForMultiBrand && barBrand ? (
              <MultiBrandSearchBar scrollToCategory={scrollToCategory} setFilter={setFilter} />
            ) : (
              <input
                ref={input}
                className={styles.input}
                value={isExact ? '' : filter || ''}
                onChange={event => setFilter(event.target.value || '')}
                type="text"
                placeholder={t('labelSearchProductCategory')}
              />
            )}
          </>
          {filter ? (
            <div className={styles.close} onClick={() => setFilter('')}>
              <MdClose size={20} />
            </div>
          ) : null}
          {showCategories ? (
            <div>
              <span className={styles.textWithIcon}>
                <MdSort />
                <span onClick={() => setOpen(true)} className={styles.spaceLeft}>
                  {t('labelShowCategories')}
                </span>
              </span>
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  )
}
