import Brands from '@page-components/Order/Products/ProductsContent/Brands'
import React from 'react'

export interface Props {
  scrollToCategory: (categoryId: string) => void
  setFilter: (any) => void
}

export default function MultiBrandSearchBar(props: Props) {
  const {scrollToCategory, setFilter} = props

  return <Brands scrollTo={category => scrollToCategory(category)} onClick={() => setFilter('')} />
}
