import {stringify} from 'querystring'
import getQueryObject from '@helpers/misc/getQueryObject'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

import useDebounce from './useDebounce'

const getQValue = router => {
  const q = getQueryObject(router)?.q
  return q ? decodeURIComponent(Array.isArray(q) ? q[0] : q) : null
}

export default function useUrlSearch({debounceTime = 500} = {}) {
  const router = useRouter()
  const [filterText, setFilter] = useState<string | null>(null)
  const debouncedFilterArr = useDebounce(filterText, debounceTime)
  const filter = debouncedFilterArr[0] ?? null

  // This useEffect takes care of loading the current category on page refresh or initial load
  useEffect(() => {
    const qValue = getQValue(router)

    if (qValue && qValue !== '' && filter !== qValue) {
      setFilter(qValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // We want to do this only on load

  // This useEffect takes care of updating the current URL to reflect the search input
  useEffect(() => {
    const query = getQueryObject(router)
    const qValue = getQValue(router)

    if (filter && qValue !== filter) {
      const newQValue = encodeURIComponent(filter)
      if (filterText !== filter) {
        setFilter(filter)
      }
      const url = `${window.location.pathname}?${stringify({...query, q: newQValue})}`
      router.push(url, url, {scroll: false})
    } else if (filter) {
      query.q = encodeURIComponent(filter)
    } else {
      delete query.q
    }
  }, [filter, router])

  return {filter, setFilter, filterText}
}
